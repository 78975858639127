"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.bindWithDispatch = exports.getServicesStatus = exports.default = void 0;

var _extends11 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _reduxActions = require("redux-actions");

var _redux = require("redux");

var _debug = _interopRequireDefault(require("debug"));

/**
 * Build a Redux compatible wrapper around a Feathers service.
 *
 * Instead of using a feathers-client service directly
 *     app.services('messages').create({ name: 'John' }, (err, data) => {...});
 * you first wrap the feathers service to expose Redux action creators and a reducer
 *     messages = reduxifyService(app, 'messages');
 * You can thereafter use the service in a standard Redux manner
 *     store.dispatch(messages.create({ name: 'John' }));
 * with async action creators being dispatched to a reducer which manages state.
 *
 * @param {Object} app the configured Feathers app, e.g. require('feathers-client')().configure(...)
 * @param {String} route is the Feathers' route for the service.
 * @param {String} name is the serviceName by which the service is known on client. Default route.
 * @param {Object} options
 * @returns {{find: *, get: *, create: *, update: *, patch: *, remove: *, on: *, reducer: *}}
 *
 * You will usually use on the client
 *      const users = reduxifyService(app, 'users');
 * However you may sometimes have awkward REST paths on the server like
 *      app.use('app.use('/verifyReset/:action/:value', ...);
 * You are then best of to use on the client
 *      const buildings = reduxifyService(app, '/verifyReset/:action/:value', 'verifyReset');
 * since you can thereafter use
 *      store.dispatch(verifyReset.create(...));
 *
 * Action creators for service calls are returned as { find, get, create, update, patch, remove }.
 * They expect the same parameters as their Feathers service methods, e.g. (id, data, params).
 *
 * Should you wish to write additional action creators, the { reducer } export expects action types
 *   'SERVICES_${SERVICE_NAME}_${METHOD}_PENDING', ...FULFILLED and ...REJECTED
 * where SERVICE_NAME is serviceName in upper case; METHOD is FIND, GET, ...
 *
 * Pro tip: You can implement optimistic updates within ...PENDING, finalizing them in ...FULFILL.
 *
 * The reducer's JS state (not immutable) is {
 *   isError: String|null,
 *   isLoading: Boolean,
 *   isSaving: Boolean,
 *   isFinished: Boolean,
 *   data: Object|null,
 *   queryResult: Object|null
 * }.
 * The find service call stores Feathers' query payload in queryResult. Other methods store in data.
 *
 * isError is Feathers' error payload { message, name, code, className, errors }.
 * If the feathers server response did not specify an error message, then the message property will
 * be feathers default of 'Error'.
 *
 * Options may change the state property names and the reducer action type names.
 *
 * Each service also gets a reset service call which re-initializes that service's state.
 * This may be used, for example, to remove isError in order to no longer render error messages.
 * store.dispatch(messages.reset(true)) will leave queryResult as is during initialization.
 *
 * An action creator for listening on service events is returned as { on } and could be used like:
 *   import feathersApp, { services } from './feathers';
 *   feathersApp.service('messages').on('created', data => { store.dispatch(
 *       services.messages.on('created', data, (event, data, dispatch, getState) => {
 *         // handle data change
 *       })
 *   ); });
 */
var reduxifyService = function reduxifyService(app, route, name, options) {
  var _Object$assign, _Object$assign2, _Object$assign3, _Object$assign4, _Object$assign5, _Object$assign6, _handleActions;

  if (name === void 0) {
    name = route;
  }

  if (options === void 0) {
    options = {};
  }

  var debug = (0, _debug.default)("reducer:" + name);
  debug("route " + route);
  var defaults = {
    idField: 'id',
    isError: 'isError',
    isLoading: 'isLoading',
    isSaving: 'isSaving',
    isFinished: 'isFinished',
    data: 'data',
    queryResult: 'queryResult',
    store: 'store',
    PENDING: 'PENDING',
    FULFILLED: 'FULFILLED',
    REJECTED: 'REJECTED'
  };
  var pendingDefaults = {
    // individual pending/loading depending on the dispatched action
    createPending: 'createPending',
    findPending: 'findPending',
    getPending: 'getPending',
    updatePending: 'updatePending',
    patchPending: 'patchPending',
    removePending: 'removePending'
  };
  var queryResultDefaults = {
    total: 0,
    limit: 0,
    skip: 0,
    data: []
  };
  var opts = Object.assign({}, defaults, pendingDefaults, options);

  var getPendingDefaults = function getPendingDefaults(slicedActionType) {
    var result = {};

    for (var key in pendingDefaults) {
      if (slicedActionType + "Pending" === pendingDefaults[key]) {
        result[key] = true;
      } else {
        result[key] = false;
      }
    }

    return result;
  };

  var SERVICE_NAME = "SERVICES_" + name.toUpperCase() + "_";
  var service = app.service(route);

  if (!service) {
    debug("redux: Feathers service '" + route + " does not exist.");
    throw Error("Feathers service '" + route + " does not exist.");
  }

  var reducerForServiceMethod = function reducerForServiceMethod(actionType, ifLoading, isFind) {
    var _ref;

    var slicedActionType = actionType.slice(SERVICE_NAME.length, actionType.length).toLowerCase(); // returns find/create/update/patch (etc.)

    var pendingResults = getPendingDefaults(slicedActionType);
    return _ref = {}, _ref[actionType + "_" + opts.PENDING] = function _(state, action) {
      var _extends2;

      debug("redux:" + actionType + "_" + opts.PENDING, action);
      return (0, _extends11.default)({}, state, pendingResults, (_extends2 = {}, _extends2[opts.isError] = null, _extends2[opts.isLoading] = ifLoading, _extends2[opts.isSaving] = !ifLoading, _extends2[opts.isFinished] = false, _extends2[opts.data] = state[opts.data] || null, _extends2[opts.queryResult] = state[opts.queryResult] || null, _extends2));
    }, _ref[actionType + "_" + opts.FULFILLED] = function _(state, action) {
      var _extends3;

      debug("redux:" + actionType + "_" + opts.FULFILLED, action);
      return (0, _extends11.default)({}, state, (_extends3 = {}, _extends3[opts.isError] = null, _extends3[opts.isLoading] = false, _extends3[opts.isSaving] = false, _extends3[opts.isFinished] = true, _extends3[opts.data] = !isFind ? action.payload : null, _extends3[opts.queryResult] = isFind ? action.payload : state[opts.queryResult] || null, _extends3[opts[slicedActionType + "Pending"]] = false, _extends3));
    }, _ref[actionType + "_" + opts.REJECTED] = function _(state, action) {
      var _extends4;

      debug("redux:" + actionType + "_" + opts.REJECTED, action);
      return (0, _extends11.default)({}, state, (_extends4 = {}, _extends4[opts.isError] = action.payload, _extends4[opts.isLoading] = false, _extends4[opts.isSaving] = false, _extends4[opts.isFinished] = true, _extends4[opts.data] = null, _extends4[opts.queryResult] = isFind ? null : state[opts.queryResult] || null, _extends4[opts[slicedActionType + "Pending"]] = false, _extends4));
    }, _ref;
  }; // ACTION TYPES


  var FIND = SERVICE_NAME + "FIND";
  var GET = SERVICE_NAME + "GET";
  var CREATE = SERVICE_NAME + "CREATE";
  var UPDATE = SERVICE_NAME + "UPDATE";
  var PATCH = SERVICE_NAME + "PATCH";
  var REMOVE = SERVICE_NAME + "REMOVE";
  var RESET = SERVICE_NAME + "RESET";
  var STORE = SERVICE_NAME + "STORE"; // FEATHERS EVENT LISTENER ACTION TYPES

  var ON_CREATED = SERVICE_NAME + "ON_CREATED";
  var ON_UPDATED = SERVICE_NAME + "ON_UPDATED";
  var ON_PATCHED = SERVICE_NAME + "ON_PATCHED";
  var ON_REMOVED = SERVICE_NAME + "ON_REMOVED";

  var actionTypesForServiceMethod = function actionTypesForServiceMethod(actionType) {
    var _ref2;

    return _ref2 = {}, _ref2["" + actionType] = "" + actionType, _ref2[actionType + "_" + opts.PENDING] = actionType + "_" + opts.PENDING, _ref2[actionType + "_" + opts.FULFILLED] = actionType + "_" + opts.FULFILLED, _ref2[actionType + "_" + opts.REJECTED] = actionType + "_" + opts.REJECTED, _ref2;
  };

  return {
    // ACTION CREATORS
    // Note: action.payload in reducer will have the value of .data below
    find: (0, _reduxActions.createAction)(FIND, function (p) {
      return {
        promise: service.find(p),
        data: undefined
      };
    }),
    get: (0, _reduxActions.createAction)(GET, function (id, p) {
      return {
        promise: service.get(id, p)
      };
    }),
    create: (0, _reduxActions.createAction)(CREATE, function (d, p) {
      return {
        promise: service.create(d, p)
      };
    }),
    update: (0, _reduxActions.createAction)(UPDATE, function (id, d, p) {
      return {
        promise: service.update(id, d, p)
      };
    }),
    patch: (0, _reduxActions.createAction)(PATCH, function (id, d, p) {
      return {
        promise: service.patch(id, d, p)
      };
    }),
    remove: (0, _reduxActions.createAction)(REMOVE, function (id, p) {
      return {
        promise: service.remove(id, p)
      };
    }),
    reset: (0, _reduxActions.createAction)(RESET),
    store: (0, _reduxActions.createAction)(STORE, function (store) {
      return store;
    }),
    on: function on(event, data, fcn) {
      return function (dispatch, getState) {
        fcn(event, data, dispatch, getState);
      };
    },
    onCreated: (0, _reduxActions.createAction)(ON_CREATED, function (payload) {
      return {
        data: payload
      };
    }),
    onUpdated: (0, _reduxActions.createAction)(ON_UPDATED, function (payload) {
      return {
        data: payload
      };
    }),
    onPatched: (0, _reduxActions.createAction)(ON_PATCHED, function (payload) {
      return {
        data: payload
      };
    }),
    onRemoved: (0, _reduxActions.createAction)(ON_REMOVED, function (payload) {
      return {
        data: payload
      };
    }),
    // ACTION TYPES
    types: (0, _extends11.default)({}, actionTypesForServiceMethod(FIND), actionTypesForServiceMethod(GET), actionTypesForServiceMethod(CREATE), actionTypesForServiceMethod(UPDATE), actionTypesForServiceMethod(PATCH), actionTypesForServiceMethod(REMOVE), {
      RESET: RESET,
      STORE: STORE
    }, actionTypesForServiceMethod(ON_CREATED), actionTypesForServiceMethod(ON_UPDATED), actionTypesForServiceMethod(ON_PATCHED), actionTypesForServiceMethod(ON_REMOVED)),
    // REDUCER
    reducer: (0, _reduxActions.handleActions)(Object.assign({}, reducerForServiceMethod(FIND, true, true), reducerForServiceMethod(GET, true), reducerForServiceMethod(CREATE, false), reducerForServiceMethod(UPDATE, false), reducerForServiceMethod(PATCH, false), reducerForServiceMethod(REMOVE, false), (_Object$assign = {}, _Object$assign[ON_CREATED] = function (state, action) {
      var _extends5;

      debug("redux:" + ON_CREATED, action);
      var updatedResult = Object.assign({}, state[opts.queryResult], {
        data: state[opts.queryResult].data.concat(action.payload.data),
        total: state[opts.queryResult].total + 1
      });
      return (0, _extends11.default)({}, state, (_extends5 = {}, _extends5[opts.queryResult] = updatedResult, _extends5));
    }, _Object$assign), (_Object$assign2 = {}, _Object$assign2[ON_UPDATED] = function (state, action) {
      var _extends6;

      debug("redux:" + ON_UPDATED, action);
      return (0, _extends11.default)({}, state, (_extends6 = {}, _extends6[opts.queryResult] = Object.assign({}, state[opts.queryResult], {
        data: state[opts.queryResult].data.map(function (item) {
          if (item[opts.idField] === action.payload.data[opts.idField]) {
            return action.payload.data;
          }

          return item;
        })
      }), _extends6));
    }, _Object$assign2), (_Object$assign3 = {}, _Object$assign3[ON_PATCHED] = function (state, action) {
      var _extends7;

      debug("redux:" + ON_PATCHED, action);
      return (0, _extends11.default)({}, state, (_extends7 = {}, _extends7[opts.queryResult] = Object.assign({}, state[opts.queryResult], {
        data: state[opts.queryResult].data.map(function (item) {
          if (item[opts.idField] === action.payload.data[opts.idField]) {
            return action.payload.data;
          }

          return item;
        })
      }), _extends7));
    }, _Object$assign3), (_Object$assign4 = {}, _Object$assign4[ON_REMOVED] = function (state, action) {
      var _extends8;

      debug("redux:" + ON_REMOVED, action);
      var removeIndex = state.queryResult.data.findIndex(function (item) {
        return item[opts.idField] === action.payload.data[opts.idField];
      });
      var updatedResult = Object.assign({}, state[opts.queryResult], {
        data: state[opts.queryResult].data.slice(0, removeIndex).concat(state[opts.queryResult].data.slice(removeIndex + 1)),
        total: state[opts.queryResult].total - 1
      });
      return (0, _extends11.default)({}, state, (_extends8 = {}, _extends8[opts.queryResult] = updatedResult, _extends8));
    }, _Object$assign4), (_Object$assign5 = {}, _Object$assign5[RESET] = function (state, action) {
      var _extends9;

      debug("redux:" + RESET, action);

      if (state[opts.isLoading] || state[opts.isSaving]) {
        return state;
      }

      return (0, _extends11.default)({}, state, (_extends9 = {}, _extends9[opts.isError] = null, _extends9[opts.isLoading] = false, _extends9[opts.isSaving] = false, _extends9[opts.isFinished] = false, _extends9[opts.data] = null, _extends9[opts.queryResult] = action.payload ? state[opts.queryResult] : queryResultDefaults, _extends9[opts.store] = null, _extends9));
    }, _Object$assign5), (_Object$assign6 = {}, _Object$assign6[STORE] = function (state, action) {
      var _extends10;

      debug("redux:" + STORE, action);
      return (0, _extends11.default)({}, state, (_extends10 = {}, _extends10[opts.store] = action.payload, _extends10));
    }, _Object$assign6)), (_handleActions = {}, _handleActions[opts.isError] = null, _handleActions[opts.isLoading] = false, _handleActions[opts.isSaving] = false, _handleActions[opts.isFinished] = false, _handleActions[opts.data] = null, _handleActions[opts.queryResult] = queryResultDefaults, _handleActions[opts.store] = null, _handleActions[opts.createPending] = false, _handleActions[opts.findPending] = false, _handleActions[opts.getPending] = false, _handleActions[opts.updatePending] = false, _handleActions[opts.patchPending] = false, _handleActions[opts.removePending] = false, _handleActions))
  };
};
/**
 * Convenience method to build wrappers for multiple services. You should this not reduxifyService.
 *
 * @param {Object} app - See reduxifyService
 * @param {Object|Array|String} routeNameMap - The feathers services to reduxify. See below.
 * @param {Object} options - See reduxifyService
 * @returns {Object} Each services' action creators. See reduxifyService.
 *
 * If the feathers server has:
 *   app.use('users', ...);
 *   app.use('/buildings/:buildingid', ...);
 * then you can do
 *   services = reduxifyServices(app, { users: 'users', '/buildings/:buildingid': 'buildings' });
 *   ...
 *   store.dispatch(users.create(...));
 *   store.dispatch(users.create(...));
 *
 * A routeNameMap of ['users', 'members'] is the same as { users: 'users', members: 'members' }.
 * A routeNameMao of 'users' is the same as { users: 'users' }.
 */


var _default = function _default(app, routeNameMap, options) {
  var services = {};
  var routeNames = {};

  if (typeof routeNameMap === 'string') {
    var _routeNames;

    routeNames = (_routeNames = {}, _routeNames[routeNameMap] = routeNameMap, _routeNames);
  } else if (Array.isArray(routeNameMap)) {
    routeNameMap.forEach(function (name) {
      routeNames[name] = name;
    });
  } else if (typeof routeNameMap === 'object') {
    routeNames = routeNameMap;
  }

  Object.keys(routeNames).forEach(function (route) {
    services[routeNames[route]] = reduxifyService(app, route, routeNames[route], options);
  });
  return services;
};
/**
 * Get a status to display as a summary of all Feathers services.
 *
 * The services are checked in serviceNames order.
 * The first service with an error message, returns that as the status.
 * Otherwise the first service loading or saving returns its status.
 *
 * @param {Object} servicesState - the slice of state containing the states for the services.
 *    state[name] has the JS state (not immutable) for service 'name'.
 * @param {Array|String} serviceNames
 * @returns {{message: string, className: string, serviceName: string}}
 *    message is the English language status text.
 *    You can create your own internationalized messages with serviceName and className.
 *    className will be isLoading, isSaving or it will be Feathers' error's className.
 */


exports.default = _default;

var getServicesStatus = function getServicesStatus(servicesState, serviceNames) {
  var status = {
    message: '',
    className: '',
    serviceName: ''
  };
  serviceNames = Array.isArray(serviceNames) ? serviceNames : [serviceNames]; // Find an error with an err.message. 'Error' is what feather returns when there is no msg text.

  var done = serviceNames.some(function (name) {
    var state = servicesState[name];

    if (state && state.isError && state.isError.message && state.isError.message !== 'Error') {
      status.message = name + ": " + state.isError.message;
      status.className = state.isError.className;
      status.serviceName = name;
      return true;
    }

    return false;
  });

  if (done) {
    return status;
  }

  serviceNames.some(function (name) {
    var state = servicesState[name];

    if (state && !state.isError && (state.isLoading || state.isSaving)) {
      status.message = name + " is " + (state.isLoading ? 'loading' : 'saving');
      status.className = state.isLoading ? 'isLoading' : 'isSaving';
      status.serviceName = name;
      return true;
    }

    return false;
  });
  return status;
};
/**
 * Method to bind a given dispatch function with the passed services.
 *
 * This helps with not having to pass down store.dispatch as a prop everywhere
 * Read More: http://redux.js.org/docs/api/bindActionCreators.html
 *
 * @param {Object} services - using the default reduxifyService method
 * @param {Function} dispatch - the relevant store.dispatch function which is to be bounded to actionCreators
 * @param {Array=} targetActions - list of action names to be targeted for binding
 * @returns {Object} boundServices - returns the new services object with the bounded action creators
 */


exports.getServicesStatus = getServicesStatus;

var bindWithDispatch = function bindWithDispatch(dispatch, services, targetActions) {
  targetActions = targetActions || [// default targets from feathers-redux
  'find', 'get', 'create', 'update', 'patch', 'remove', 'reset', 'store', // couple more optional ones in case feathers-reduxify-authentication is being used
  'authenticate', 'logout'];

  var _serviceNames = Object.keys(services); // map over the services object to get every service


  _serviceNames.forEach(function (_name) {
    var _methodNames = Object.keys(services[_name]); // map over every method in the service


    _methodNames.forEach(function (_method) {
      // if method is in targeted actions then replace it with bounded method
      if (targetActions.includes(_method)) {
        services[_name][_method] = (0, _redux.bindActionCreators)(services[_name][_method], dispatch);
      }
    });
  });

  return services;
};

exports.bindWithDispatch = bindWithDispatch;